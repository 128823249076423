import axios from 'axios';
import { APIResponse, OrderDownload } from '@arema/components/Classes';
import i18n from 'i18next';
const ROOT_URL = process.env.REACT_APP_API_URL
const DEBUG = process.env.REACT_APP_DEBUG;

async function post<T=any>(endpoint: string, data?: any) : Promise<APIResponse<T>>{
	var options = {
		headers: {
			'show-code': DEBUG ? 1 : 0,
			'token': '',
			'Content-Type': 'application/json'
		}
	}
	if(data instanceof FormData){
		options.headers['Content-Type'] = 'multipart/form-data';
	}

	try{
		var ENDPOINT = `${ROOT_URL}/public/${endpoint}`;
		if(DEBUG) console.log("POST "+ENDPOINT);
		var res = await axios.post(ENDPOINT, data, options);
		if(DEBUG) console.log(` => Response: ${(res.headers['content-length']/1024).toFixed(2)} KB`);
		return res.data as APIResponse<T>;
	}catch(err){
		if(DEBUG) console.error(err);
		return {
			error: true, 
			message: i18n.t('common.unexpected_code', { code: 'APIP-LCL-1' }),
			code: 'APIP-LCL-1',
		}
	}
};

var CACHE : { [x: string]: APIResponse<any> } = {};

var cachePost = async <T=any>(endpoint: string, key: string, data?: any) : Promise<APIResponse<T>>=>{
	if(CACHE[key]) return CACHE[key] as APIResponse<T>;
	var res = await post<T>(endpoint, data);
	if(!res.error) CACHE[key] = res;
	return res;
}

var getTickets = (hash: string)=>{
	return post<OrderDownload>('tickets/get', { hash });
}

export default {
	getTickets,	
}